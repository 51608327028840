.home.dark-mode {
    background: black;
    .axe-labels .label {
        background: black;
    }
    .touch-hint .inside {
        background: black;
        box-shadow: $blue 0 0 0 1.5px inset;
    }
    .dotted-grid {
        opacity: 0.2;
    }
    .axe-labels {
        opacity: 0.7;
    }
    .sentences {
        color: $background;
    }
}
