.dotted-grid {
    position: absolute;
    display: grid;
    $columns: 8;
    grid-template-columns: repeat($columns, 1fr);

    $columnsWide: 16;
    @media (min-aspect-ratio: 8/5) {
        grid-template-columns: repeat($columnsWide, 1fr);
    }

    grid-auto-rows: auto;
    height: 100%;
    width: 100%;
    $dot-size: 0.2em;
    direction: ltr;

    left: 0- ($dot-size/2);
    top: 0- ($dot-size/2);

    .dot {
        &:before,
        &:after,
        .last-row,
        .last-last {
            background: $light-gray;
            width: $dot-size;
            height: $dot-size;
            border-radius: 100%;
            display: block;
            content: "";
        }
        &:before {
        }
        &:after,
        .last-row,
        .last-last {
            display: none;
        }

        $first-child-in-any-row: "&:nth-child(#{$columns}n + 1)";
        $any-child-in-the-last-row: ":nth-last-child(-n + #{$columns})";
        $first-child-in-last-row: "&#{$first-child-in-any-row}#{$any-child-in-the-last-row}";
        // last child of row
        &:nth-child(#{$columns}n + 0) {
            position: relative;
            &:after {
                position: absolute;
                top: 0;
                left: 100%;
                // background-color: yellow;
                display: block;
            }
        }
        // all last row items
        &:nth-last-child(-n + #{$columns}) {
            position: relative;
            & .last-row {
                position: absolute;
                top: 100%;
                left: 0;
                // background: red;
                display: block;
            }
        }

        &:last-child {
            position: relative;
            .last-last {
                position: absolute;
                top: 100%;
                left: 100%;
                display: block;
                // background: blue;
            }
        }

        @media (min-aspect-ratio: 8/5) {
            // last child of row
            &:nth-child(#{$columnsWide}n + 0) {
                position: relative;
                &:after {
                    position: absolute;
                    top: 0;
                    left: 100%;
                    // background-color: yellow;
                    display: block;
                }
            }
            // all last row items
            &:nth-last-child(-n + #{$columnsWide}) {
                position: relative;
                & .last-row {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    // background: red;
                    display: block;
                }
            }
        }
    }
}
