.sentences {
    color: $blue;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: font-variation-settings 1300ms $ease;
    will-change: font-variation-settings;
    // will-change: font-variation-settings, contents;
    .sentence {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        line-height: 0.9939393939393939em;
        $hide-duration: 250ms;
        $initial_wait: $hide-duration + 60ms;
        $duration: 1000ms;
        $delay_between_each: 30ms;

        font-size: calc(min(13vw, 10vh));
        padding-bottom: 0.13em;
        line-height: 1.1;

        
        letter-spacing: -0.014em;

        &.xxl {
            font-size: calc(min(92vw, 80vh));
            letter-spacing: -0.03em;
            line-height: 1;
        }
        &.l {
            font-size: calc(min(18.5vw, 17.5vh));
            letter-spacing: -0.022em;
            line-height: 1.1;
        }
        &.m {
            font-size: calc(min(11vw, 6vh));
            letter-spacing: 0.012em;
            line-height: 1.2;
        }
        &.s {
            font-size: calc(min(4.5vw, 4vh));
            text-align: start;
            margin: 0 auto;
            line-height: 1.48;
            letter-spacing: 0.020em;
            width: calc(min(75vw, 106vh));
            &.active {
                span.word {
                    @for $i from 0 through 100 {
                        &:nth-child(#{$i}) {
                            animation-delay: $initial_wait + $delay_between_each/3 * $i;
                        }
                    }
                }
            }
        }
        &.xs {
            font-size: calc(min(4.4vw, 3.4vh));
            text-align: start;
            margin: 0 auto;
            line-height: 1.52;
            letter-spacing: 0.020em;
            width: calc(min(82vw, 106vh));
            &.active {
                span.word {
                    @for $i from 0 through 100 {
                        &:nth-child(#{$i}) {
                            animation-delay: $initial_wait + $delay_between_each/3 * $i;
                        }
                    }
                }
            }
        }

        span.word {
            display: inline-block;
            transform-origin: center;
        }

        opacity: 0;

        &.hidden {
            // font-variation-settings: initial !important;
            transition: transform $ease $hide-duration, opacity $ease $hide-duration;
            transform: scale(0.95);
            opacity: 0;
            span.word {
            }
        }
        &.active {
            opacity: 1;
            transform: scale(1);
            span.word {
                opacity: 0;
                @keyframes slideUp {
                    0% {
                        transform: translateY(4vw) scale(1);
                        opacity: 0;
                    }
                    100% {
                        transform: translateY(-0vw) scale(1);
                        opacity: 1;
                    }
                }
                animation: slideUp $duration $ease forwards;
                @for $i from 0 through 100 {
                    &:nth-child(#{$i}) {
                        animation-delay: $initial_wait + $delay_between_each * $i;
                    }
                }
            }
        }
    }
}
