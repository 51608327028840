.home {
    height: 100vh;
    // padding: 6.2vh 6.2vh;
    padding: 3.8vh 3.8vh;
    user-select: none;
    overflow: hidden;
    cursor: none;
    > .inside {
        height: 100%;
        position: relative;
    }

}