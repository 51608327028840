.axe-labels {
    .label {
        position: absolute;
        font-size: 1.125em;
        // letter-spacing: 1.075;
            letter-spacing: 0.075em;
        line-height: 1.2;
        background-color: $background;
        font-size: calc(min(1.65vw, 1.5vh));
        padding: 0.3em;
        margin: -0.9em -0.6em;
        &.top-left {
            top: 0;
            left: 0;
            text-align: left;
        }
        &.top-center {
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
        }
        &.top-right {
            top: 0;
            right: 0;
            text-align: right;
        }
        &.right-center {
            top: 63.3%;
            right: 0;
            text-align: right;
            transform: translateY(-50%);
        }
        &.right-bottom {
            bottom: 0;
            right: 0;
            text-align: right;
        }
    }
}
