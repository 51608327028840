.interactive-mode {
    .mouse-cursor {
        > .inside {
            transform: scale(1) rotate(0deg);
            > .inside {
            }
        }
    }
}
.mouse-cursor {
    width: 1px;
    height: 1px;
    position: absolute;
    left: 0;
    top: 0;
    direction: ltr;
    // transform: translateX(30vw);
    text-align: center;
    transition: transform 800ms cubic-bezier(0.155, 0.875, 0.000, 1.000);
    // transition: transform 800ms $ease;
    will-change: transition;
    > .inside {
        transition: transform 1500ms $ease;
        transform: scale(0) rotate(-70deg);
        transform-origin: top;
        > .inside {
            transform: translate(-50%, -50%);
            background-color: $blue;
            will-change: contents;

            font-size: calc(min(1.8vw, 1.5vh));
            letter-spacing: 0.075em;
            width: 3.4em;
            height: 3.4em;
            display: flex;
            color: $background;
            align-items: center;
            justify-content: center;
            font-family: $default-font;
            // font-weight: 700;
            border-radius: 100%;
            box-shadow: 0 1em 1em rgba(0, 0, 0, 0.3);
        }
    }
}
