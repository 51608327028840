.interactive-mode {
    .touch-hint {
        .inside {
            transform: translateY(170%) scale(0.8);
        }
    }
}
.touch-hint {
    position: absolute;
    left: 50%;
    bottom: -0.5em;
    direction: ltr;
    text-align: center;
    transform: translateX(-50%);
    opacity: 0.97;
    // transform: scale(0) rotate(-70deg);
    // &.is-moving {
    //     transform: scale(1) rotate(0deg);
    // }
    .inside {
        transition: transform 1000ms $ease;

        background-color: $background;

        font-size: calc(min(4vw, 3.5vh));
        width: 7em;
        height: 4.2em;
        border-radius: 0.35em;
        padding-top: 0.8em;
        color: $blue;
        text-align: center;
        // font-weight: 700;
        box-shadow: 0 0.4em 0.7em rgba(0, 0, 0, 0.3);
        img {
            display: block;
            margin: 0 auto;
        }
        .touchpad {
            width: 1.5em;
        }
        .pointer {
            margin-top: -0.5em;
            width: 0.85em;
            margin-bottom: 0.5em;
            @keyframes moveCursor {
                0% {
                    transform: translate(-0.4em, -0.35em);
                }
                40% {
                    transform: translate(0.5em, 0.1em);
                }
                60% {
                    transform: translate(0.25em, -0.3em);
                }
                80% {
                    transform: translate(-0.1em, 0.1em);
                }
                100% {
                    transform: translate(-0.4em, -0.35em);
                }
            }
            animation: moveCursor 4s ease infinite;
        }
        .arrow {
            @keyframes moveArrow {
                0% {
                    transform: translateY(-0.1em);

                }
                50% {
                    transform: translateY(0.1em);
                }
                100% {
                    transform: translateY(-0.1em);
                }
            }
            width: 0.47em;
            animation: moveArrow 1.3s ease-in-out infinite;
        }
    }
}
