$vf: 'beinayim';

@font-face {
    font-family: $vf;
    src: url("../../fonts/Beinayim/Variable/BeinayimV1.3GX.ttf");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
// @font-face {
//     font-family: $vf;
//     src: url("../../fonts/hafonton/FtAcapella\ 0.93-VF.ttf");
//     src: url("../../fonts/hafonton/FtDelicattes\ Gadol\ VF\ 0.95-VF.ttf");
//     src: url("../../fonts/hafonton/FtMonopol\ 2.07-VF.ttf");
//     font-style: normal;
//     font-weight: normal;
//     text-rendering: optimizeLegibility;
// }


$default-font: tahoma, sans-serif;
$font: $vf, $default-font;

body {
    font-family: $font;
    font-size: 100%;
    font-variation-settings: 'wght' 400, 'serf' 100;
}